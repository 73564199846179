;(function ($, root, undefined) {
    $(document).ready(function () {

        $('.anchorRegister').each(function () {
            $(this).click(function () {
                var body = $('html, body');
                body.stop().animate({scrollTop: $('.wrappPeople').offset().top}, 1000, 'swing');
            })
        })

        function generate_random(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min) + "%"
        }

        function face_efects() {
            $('.people_1').animate({
                left: generate_random(10, 14),
                top: generate_random(20, 24)
            }, 500, 'swing')

            $('.people_2').animate({
                right: generate_random(10, 14),
                top: generate_random(20, 25)
            }, 500, 'swing')

            $('.people_3').animate({
                right: generate_random(13, 15),
                top: generate_random(67, 71)
            }, 500, 'swing')

            $('.people_4').animate({
                left: generate_random(14, 19),
                top: generate_random(55, 59)
            }, 500, 'swing')
        }

        $('.wrappForm').mouseover(function () {
            face_efects()
        }).mouseout(function () {
            $('.people_1').stop(true, false);
            $('.people_2').stop(true, false);
            $('.people_3').stop(true, false);
            $('.people_4').stop(true, false);
        });

        var goAnimation = true;
        $(document).on('scroll', function (e) {
            var desplazamientoActual = $(document).scrollTop();
            if (desplazamientoActual >= $('.wrappBgBubbles').offset().top && goAnimation) {
                $('.wrappBgBubbles .wrapItem').each(function (i) {
                    $(this).addClass('animate__flipInY animate__delay-' + ((i + 1) - 1) + 's')
                })
                goAnimation = false
            }
        })

    })
})(jQuery, this)